<template>
  <div class="fill-height">
    <Slider />

    <section class=" mt-5">
      <div class="container">
        
        <div class="mb-5"><h2>Upcoming Matches</h2></div>
        <v-row class="no-padd matchsec">
          <v-col md="3" sm="">
            <v-tabs v-model="matchtab" class="whitetab" background-color="primary" color="white" @change="changegame"  vertical v-if="window.width > 768">
              <v-tab :value="v" v-for="(v,k) in games" :key="k">{{ v }}</v-tab>
            </v-tabs>
            <v-tabs v-model="matchtab" class="whitetab" background-color="primary" color="white" @change="changegame"  v-else>
              <v-tab :value="v" v-for="(v,k) in games" :key="k">{{ v }}</v-tab>
            </v-tabs>
        </v-col>
        <v-col md="9" sm="12">

          <div class="match-listing" v-if="schedule && !showload">
          <div class="single-match">
            <div class="part-head">
                <span class="match-venue">Venue : {{ schedule.venue }} </span>
            </div>
            <div class="part-team">
              <div class="single-team hometeam">
                <div class="logo">
                   <img :src="schedule.hometeamlogo" :title="schedule.hometeamname" :alt="schedule.hometeamname">
                </div>
                <span class="team-name">{{ schedule.hometeamshortname }}</span>
              </div>
              <div class="match-details">
                <div class="match-time">
                  <span class="date">{{ schedule.date }}</span>
                  <span class="time">{{ schedule.time }}</span>
                </div>
                <span class="versase">vs</span>
              </div>
              <div class="single-team awayteam">
                <div class="logo">
                   <img :src="schedule.awayteamlogo" :title="schedule.awayteamname" :alt="schedule.awayteamname">
                </div>
                <span class="team-name">{{schedule.awayteamshortname}}</span>
              </div>  
              </div>
            </div>
          </div>
          <div v-if="showload" class="text-center">
            <v-progress-circular 
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          </div>
          
        </v-col>
      </v-row>
      </div> 
    </section>   

    <section class="fullwidth-mid mt-10">
      <table id="u_body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;Margin: 0 auto;background-color: #ecf0f1;width:100%" cellpadding="0" cellspacing="0">
        <tbody>
            <tr style="vertical-align: top">
                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                        <div class="u-row" style="Margin: 0 auto;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                <div class="u-col u-col-100" style="display: table-cell;vertical-align: top;">
                                    <div style="background: linear-gradient(275.78deg,#d00 0,#101113 56.35%);height: 100%;width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->
                                            <table id="u_content_image_1"  role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:60px 10px 10px 30px;" align="left">
                                                            <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                <tr>
                                                                    <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="left">
                                                                        <img align="left" border="0" src="../assets/logo.png" alt="image" title="image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;float: none;width: 225px;height: auto;" width="106.4" class="v-src-width v-src-max-width" />
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table id="u_content_heading_1"  role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 30px 30px;" align="left">
                                                            <h1 class="v-text-align v-font-size" style="margin: 0px;color: #ffffff;line-height: 120%;text-align: left;word-wrap: break-word; font-size: 32px;font-weight: bolder;">PUT YOUR FOOTY KNOWLEDGE<br/> TO THE TEST AND YOU COULD<br/> WIN $1 MILLION!</h1>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table id="u_content_divider_1" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px 0px 30px 30px;" align="left">
                                                            <table height="0px" align="left" border="0" cellpadding="0" cellspacing="0" width="60%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                <tbody>
                                                                    <tr style="vertical-align: top">
                                                                        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <span>&#160;</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table id="u_content_text_1"  role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px 30px 30px;" align="left">
                                                            <div class="v-text-align" style="color: #ffffff; line-height: 170%; text-align: justify; word-wrap: break-word;">
                                                                <p style="font-size: 14px;line-height: 170%;margin-bottom: 15px;">DamnN Sports Tips is now the biggest footy tipping competition in Australia and probably the World and if you think that you have what it takes to pick the winners of the AFL or NRL every round then you could possibly walk away with $1 million come the end of the season.</p>
                                                                <p style="font-size: 14px;line-height: 170%;margin-bottom: 15px;">For as little as a pint of beer or a coffee and cake you can play DamnN Sports Tips AFL or DamnN Sports Tips NRL or both. $12 is all it costs to play for the whole year which is basically 50c a week.</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table id="u_content_image_2" style="font-family:'Montserrat',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px 0 0;font-family:'Montserrat',sans-serif;" align="left">
                                                            <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                <tr>
                                                                    <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">
                                                                        <img align="center" border="0" src="../assets/screen-full.jpg" alt="image" title="image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;" class="v-src-width v-src-max-width" />
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table id="u_content_button_1" style="background-color: #151617;font-family:'Montserrat',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-container-padding-padding" style="overflow-wrap: break-word;word-break: break-word;padding: 60px 10px 60px;font-family: 'Montserrat',sans-serif;" align="left">
                                                            <div class="v-text-align" align="center">
                                                                <router-link v-if="!loggedIn" :to="{name: 'Auth',query:{action:'register'}}"  class="v-button v-size-width" style="box-sizing: border-box;display: inline-block;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #ffffff; background-color: #d00; border-radius: 20px;-webkit-border-radius: 20px; -moz-border-radius: 20px; width:30%; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">
                                                                    <span style="display:block;padding:10px 20px;line-height:120%;"><strong><span style="font-size: 14px; line-height: 16.8px;">Sign Up Now</span></strong></span>
                                                                </router-link>
                                                            </div>
                                                            <div class="v-text-align" style="color: #ffffff; line-height: 170%; text-align: center; word-wrap: break-word;">
                                                                <p style="font-size: 14px;line-height: 170%;margin-top: 30px;">If it’s too good to be true sometimes it is.</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </section>
    <section class="fullwidth-mid mt-10">
     <div class="container">
      <div class="row play_row d-flex align-items-center">
       <div class="col-lg-5">
        <div class="inner">
          <h2 class="section_title">Play all the sports you love, all in one place!</h2>
          <div class="inner_row d-flex align-items-center">
           <div class="inner_col">
             <div class="left_col"><img src="../assets/payment.png" alt="Secure Payments"></div>
           </div>
           <div class="inner_col">
             <div class="right_col"><h5>Secure Payments</h5></div>
           </div>
          </div>
          <div class="inner_row d-flex align-items-center">
           <div class="inner_col">
             <div class="left_col"><img src="../assets/transparent.png" alt="Transparent"></div>
           </div>
           <div class="inner_col">
            <div class="right_col"><h5>Transparent</h5></div>
           </div>
          </div>
          <div class="inner_row d-flex align-items-center">
            <div class="inner_col">
              <div class="left_col"><img src="../assets/multi.png" alt="Multi Sports"></div>
            </div>
            <div class="inner_col">
              <div class="right_col"><h5>Multi-Sports</h5></div>
            </div>
          </div>
          <div class="inner_row d-flex align-items-center">
            <div class="inner_col">
              <div class="left_col"><img src="../assets/support.png" alt="24/7 Support"></div>
            </div>
            <div class="inner_col">
              <div class="right_col"><h5>24/7 Support</h5></div>
            </div>
          </div>
         </div>
       </div>
       <div class="col-lg-7">
         <div class="inner text-center text-lg-end"><img src="../assets/illustration.png" alt="Play Illustration"></div>
       </div>
     </div>
    </div>
   </section>
 </div>
</template>

<script>
  import Slider from '../components/Slider.vue';

  export default {
    name: 'Home',
    components: {
      Slider,
     
    },
    data () {
      return {
        topplayer:0,
        games:{0:"NFL",1:"EPL"},
        matchtab:0,
        schedule:[],
        window: {
            width: 0,
            height: 0
        },
        showload:false
      }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed:{
        loggedIn() {
          return this.$store.getters.loggedIn;
        },
    },
    created(){
      this.changegame();
      window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }, 
    methods:{
      handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
      changegame(){
        var game = this.games[this.matchtab];
        this.schedule = [];
        this.showload = true;
        this.$store.dispatch("getFutureGame",{sport:game}).then((response) => {
           this.schedule = response.data;
           this.showload = false;
        });
      }
    }
  }
</script>
<style scoped>
.games {display: flex;}
.box {flex: 0 0 20%;margin-right: 10px;background: #1976d2;height: 150px;transition-duration: 0.5s;border-radius: 10px;text-align: center;}
.box:hover{box-shadow: 0 0 8px rgb(0 0 0 / 60%);}
.oval {width: 80px;margin: auto;display: flex;height: 80px;background: white;margin-top: 15px;border-radius: 30%;text-align: center;justify-content: initial;}
.oval img {width: 83%;  margin: auto;  display: block;}
.box h1.title {  color: white;  padding-top: 10px;  font-size: 19px !important;}
h2.midheading {font-size: 24px;  font-weight: 500;  text-align: center;  margin: 1rem 0;}
.smallheading{font-size: 18px;  text-align: center;  font-weight: 300; }
section.fullwidth-mid{background: rgb(21, 22, 23);}
.text-lg-end {  text-align: right!important;}
.fullwidth-mid{color: white;}
section .container .play_row .col-lg-7 .inner img { max-width: 100%;  height: auto;}
section .container .play_row .col-lg-5 .inner .inner_row .inner_col .right_col h5 { font-family: "Rubik", sans-serif; font-size: 20px;  font-weight: 700;  line-height: 1.5;  color: #fafaff;}
section .container .play_row .col-lg-5 .inner .inner_row .inner_col .left_col {  margin-right: 40px;}
section .container .play_row .col-lg-5 .inner .inner_row { padding: 15px 40px;margin: 30px 10px;  border: 1px solid rgba(205, 209, 216, 0.5);  -webkit-transform: skewX(-10deg);  -ms-transform: skewX(-10deg);  transform: skewX(-10deg);}
@media only screen and (min-width: 1200px){
  section .container .play_row .col-lg-7 {  background-image: none;    position: relative;}
}
.topplayers .owl-car{display: flex;justify-content: center;}
.topplayers .owl-item { -webkit-box-flex: 0;    flex: 0 0 15%;  height: 160px;  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 20%);  margin-right: 20px;  margin-top: 20px;  border-radius: 15px;  background:#1976d2;transform: perspective(1px) translateZ(0);transition-property:transform}
.topplayers .owl-item:hover{transform: scale(1.1);}
.img_wrapper img { width: 50%;  margin: auto;  display: block;}
.bottom_col{text-align: center;}
.bottom_col, .bottom_col a{color: white; text-decoration: none;}
.img_col { background: white;  border-top-left-radius: 13px;  border-top-right-radius: 13px;}
.bottom_col h5 {  padding-top: 8px;  font-size: 16px;}
.twoblocks {  display: flex;  flex-direction: row;  flex-wrap: nowrap;  justify-content: flex-start;  align-content: stretch;  align-items: flex-start;  }
.leagueblock:nth-child(1) {  order: 0;  flex: 0 1 55%; margin-right: 7px; align-self: auto;  }
.ladderblock:nth-child(2) {  order: 0;  flex: 0 1 43%;  align-self: auto;  }
>>> .v-tab:before, >>> .v-tabs-slider {background-color: #fff !important;}
>>> .v-tab{color: white !important;}
</style>
<style>
.mytable .v-data-table-header{background: #1976d2;}
.mytable .v-data-table-header th span{color: white !important;}

</style>
<style lang="scss">  
  tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>