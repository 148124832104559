import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import SlitherSlider from 'slither-slider';
import '@mdi/font/css/materialdesignicons.css'
import InfiniteLoading from 'vue-infinite-loading';

const VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)
Vue.use(SlitherSlider)
Vue.use(InfiniteLoading); 

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
