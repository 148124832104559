<template>
    <div>
        <transition-group name='fade' tag='div' class="slidercontent">
          <div v-for="i in [currentIndex]" :key='i'>
            <img :src="require('@/assets/'+currentImg)" />
          </div>
        </transition-group>
       
      </div>
    </template>
    
    <script>
    export default {
      name: 'Slider',
      data() {
        return {
          images: [
            '4.jpg',
            '5.jpg',
            '1.jpg',
            '2.jpg',
            '3.png',
            ],
          timer: null,
          currentIndex: 0,
        }
      },
        mounted: function() {
          this.startSlide();
        },
      
        methods: {
          startSlide: function() {
            this.timer = setInterval(this.next, 8000);
          },
      
      
          next: function() {
            this.currentIndex += 1
          },
          prev: function() {
            this.currentIndex -= 1
          }
        },
      
        computed: {
          currentImg: function() {
            return this.images[Math.abs(this.currentIndex) % this.images.length];
          }
        }
      
    }
    </script>
    
    <style scoped>
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.9s ease;
      overflow: hidden;
      visibility: visible;
      position: absolute;
      width:100%;
      opacity: 1;
    }
    
    .fade-enter,
    .fade-leave-to {
      visibility: hidden;
      width:100%;
      opacity: 0;
    }
    .slidercontent
    {
        height:560px;
        width:100%;
        background:#fff;
          }
   img {
    height:560px;
    width:100%
      }
    
    .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 16px;
      color: white;
      font-weight: bold;
      font-size: 18px;
      transition: 0.7s ease;
      border-radius: 0 4px 4px 0;
      text-decoration: none;
      user-select: none;
    }
    
    /* Position the "next button" to the right */
    .next {
      right: 0;
    }
    
    .prev {
      left: 0;
    }
    
    /* On hover, add a black background color with a little bit see-through */
    .prev:hover, .next:hover {
      background-color: rgba(0,0,0,0.9);
    }
    </style>