<template>
  <div class="fix-height container">
     <h2>My Dashboard</h2>
     <div class="games-blocks" v-if="!hideallthing">
        <div class="flex-items">
          <div class="dashboard-top">
             <div class="part-one">
              <span><img src="../assets/afl.png" class="smalllogo" /></span>
              <h3>AFL - Round {{Aflweek}}</h3>
             </div>
             <div class="part-two d-flex" >
               <div v-if="Afldays > 0" class="information">Starts in <strong style="margin-left: 4px;"> {{Afldays}} Day<span v-if="Afldays > 1">s</span></strong></div>
               <v-btn v-if="Aflpurchased == 0" color="primary" @click="checkconfirm('AFL')"  class="white--text purchase" rounded>Join Now <span>($12)</span></v-btn>
               <v-btn v-else color="green" :to="{name:'tipping',params:{sport:'AFL'}}" class="white--text" rounded>
                 <span v-if="AflEntry == 0">Enter Tips</span>
                 <span v-else>Edit Tips</span>
               </v-btn>
             </div>
          </div>
        </div>
        <div class="flex-items">
          <div class="dashboard-top">
             <div class="part-one">
              <span><img src="../assets/nrl.png" class="smalllogo" /></span>
              <h3>NRL - Round {{Nrlweek}}</h3>
             </div>
             <div class="part-two d-flex" >
              <div v-if="Nrldays > 0" class="information">Starts in <strong style="margin-left: 4px;"> {{Nrldays}} Day<span v-if="Nrldays > 1">s</span></strong></div>
              <v-btn v-if="Nrlpuchased == 0" color="primary" @click="checkconfirm('NRL')"  class="white--text purchase" rounded>Join Now <span>($12)</span></v-btn>
              <v-btn v-else color="green" :to="{name:'tipping',params:{sport:'NRL'}}" class="white--text" rounded>
                <span v-if="NrlEntry == 0">Enter Tips</span>
                <span v-else>Edit Tips</span>
              </v-btn>
             </div>
          </div>
        </div>
        <div class="flex-items">
          <div class="dashboard-top">
             <div class="part-one">
              <span><img src="../assets/epl.png" class="smalllogo" /></span>
              <h3>EPL - Round {{Footballweek}}</h3>
             </div>
             <div class="part-two d-flex" >
              <div v-if="Footballdays > 0" class="information">Starts in <strong style="margin-left: 4px;"> {{Footballdays}} Day<span v-if="Footballdays > 1">s</span></strong></div>
              <v-btn v-if="Footballpuchased == 0" color="primary" @click="checkconfirm('Football')"  class="white--text purchase" rounded>Join Now <span>($12)</span></v-btn>
              <v-btn v-else color="green" :to="{name:'tipping',params:{sport:'Football'}}" class="white--text" rounded>
                <span v-if="FootballEntry == 0">Enter Tips</span>
                <span v-else>Edit Tips</span>
              </v-btn>
             </div>
          </div>
        </div>
        
          <div class="flex-items">
          <div class="dashboard-top">
             <div class="part-one">
              <span><img src="../assets/nfl.png" class="smalllogo" /></span>
              <h3>NFL - Round {{Nflweek}}</h3>
             </div>
             <div class="part-two d-flex" >
              <div v-if="Footballdays > 0" class="information">Starts in <strong style="margin-left: 4px;"> {{Nfldays}} Day<span v-if="Nfldays > 1">s</span></strong></div>
              <v-btn v-if="Nflpuchased == 0" color="primary" @click="checkconfirm('NFL')"  class="white--text purchase" rounded>Join Now <span>($12)</span></v-btn>
              <v-btn v-else color="green" :to="{name:'tipping',params:{sport:'NFL'}}" class="white--text" rounded>
                <span v-if="FootballEntry == 0">Enter Tips</span>
                <span v-else>Edit Tips</span>
              </v-btn>
             </div>
          </div>
        </div>
       
     </div>
     <v-dialog v-model="purchasemodel" persistent max-width="350">
       <v-card> 
         <v-card-text class="pt-5 pb-5">
           <div>Do you want to join the {{ sport }} sports tips?</div>
          </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="red darken-1" :disabled="disablebtn" text @click="purchasemodel = false">No</v-btn>
           <v-btn color="primary darken-1" :disabled="disablebtn" text @click="buyLeague">Yes</v-btn>
         </v-card-actions>  
       </v-card>  
       <v-progress-linear indeterminate color="red darken-2" v-if="showprogress"></v-progress-linear>
     </v-dialog>
  </div>
</template>
<script>
export default{
  name:'Dashboard',
  data(){
    return{
      hideallthing:true,
      Aflpurchased:0,
      AflEntry:0,
      Afldays:0,
      Aflweek:0,
      Nrlpuchased:0,
      NrlEntry:0,
      Nrldays:0,
      Nrlweek:0,
      Footballpuchased:0,
      FootballEntry:0,
      Footballdays:0,
      Footballweek:0,
      Nflpuchased:0,
      NflEntry:0,
      Nfldays:0,
      Nflweek:0,
      sport:'',
      purchasemodel:false,
      showprogress:false,
      disablebtn:false,
      error:false,
      showaddBalance:false,
      errorText:''
    }
  },
  created(){
     this.$store.commit('setsiteloader',true);
     this.getPurchasedLeagues();
     this.$root.$refs.Master.getPurchasedLeagues();
  }, 
  methods:{
    opendeposite(){
          this.$root.$refs.Master.opendeposit();
      },
    checkconfirm(sport){
       this.sport = sport;
       this.purchasemodel = true;
    },
    getPurchasedLeagues(){
       this.$store.dispatch("getPuchasedLeagues").then((response) => {
        this.$store.commit('setsiteloader',false);
        this.hideallthing = false;
         if(response.status == "success")
         {
           this.Afldays = response.data.AFL.days;
           if(typeof response.data.NRL != 'undefined')
           {
              this.Nrldays = response.data.NRL.days;
              
              if(typeof response.data.NRL != 'undefined')
              {
                if(response.data.NRL.purchased == 1)
                {
                  this.Nrlpuchased = 1;
                }
                if(response.data.NRL.entry == 1)
                {
                  this.NrlEntry = 1;
                }
              }
           }
           if(typeof response.data.Football != 'undefined')
           {
              this.Footballdays = response.data.Football.days;
              
              if(typeof response.data.Football != 'undefined')
              {
                if(response.data.Football.purchased == 1)
                {
                  this.Footballpuchased = 1;
                }
                if(response.data.Football.entry == 1)
                {
                  this.FootballEntry = 1;
                }
              }
           }
           if(typeof response.data.NFL != 'undefined')
           {
              this.Nfldays = response.data.NFL.days;
              
              if(typeof response.data.NFL != 'undefined')
              {
                if(response.data.NFL.purchased == 1)
                {
                  this.Nflpuchased = 1;
                }
                if(response.data.NFL.entry == 1)
                {
                  this.NflEntry = 1;
                }
              }
           }
           this.Aflweek = response.data.AFL.week;
           this.Nrlweek = response.data.NRL.week;
           this.Footballweek = response.data.Football.week;
           if(typeof response.data.NFL != "undefined")
           {
            this.Nflweek = response.data.NFL.week;
           }
           
           if(response.data.AFL.purchased == 1)
           {
             this.Aflpurchased = 1;
           }
           if(response.data.AFL.entry == 1)
           {
             this.AflEntry = 1;
           }
           
         }
       });
    },
    buyLeague(){
      this.showprogress = true;
      this.disablebtn = true;
      this.$store.dispatch("joinLeague",{sport:this.sport}).then((response) => {
        this.showprogress = false;
        this.disablebtn = false;
        if(response.status == "error")
        {
          if(response.data == "low blance")
          {
            this.opendeposite();
          } 
        } 
        else
        {
           if(response.status == "success")
           {
             this.$store.commit('setsiteloader',true);
             this.purchasemodel = false;
             this.getPurchasedLeagues();
             this.$store.dispatch("retrieveBalance");
           }
        }
      });
    }
  }
}
</script>
<style scoped>
.dashboard-top{display: flex; padding-top: 10px; }
.dashboard-top div{flex: 0 0 50%; display: flex; align-items: center;}
.information{background-color: #ffb759;min-width: 100px;height: 32px;align-self: center;color: #101113;border-radius: 10px;border: none;padding: 0 12px;box-sizing: border-box;display: inline-flex;justify-content: center;align-items: center;font-size: .75rem;margin-right: 24px;}
.games-blocks h3 {margin: 0;align-self: center;font-size: 21px;font-style: normal;font-weight: 600;line-height: 25px;letter-spacing: 0;text-align: left;margin-left: 0.8125rem;}
.part-one{text-align: left;}
.part-two{justify-content: right; }
.smalllogo{width: 50px; height: 50px;}
.games-blocks { display: flex; flex-direction: row; flex-wrap: wrap; /* justify-content: center; align-items: normal; align-content: normal; */  }
.purchase span {
    font-size: 10px;
    padding-top: 2px;
}
.flex-items { width: 48%; min-height: 80px;border-radius: 18px;background: white;padding: 0 20px;margin-right: 15px;margin-bottom: 10px;margin-top: 20px;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;}
.flex-items:nth-child(1) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;}
.flex-items:nth-child(2) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;}
.flex-items:nth-child(3) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;}
.flex-items:nth-child(4) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;}
.flex-items:nth-child(5) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;}
@media (max-width: 1180px) {
  .flex-items { width: 100%; display: flex;flex-flow: column;margin-right: 0px; min-height: 80px !important;}
  
}    
@media (max-width: 480px) {
  
  .dashboard-top{display: block; text-align: center;    padding-bottom: 10px;}
}  
</style>