<template>
  <div>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="3" >
            <div v-if="window.width >= 1024"><img style="border:1px dotted gray" src="../assets/st-banner.png" /></div>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="mt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" class="text-center ">
              <div class="actiontab">
                <v-tabs v-model="whichtab" centered  class="themetab" color="#383838">
                    <v-tab>Login</v-tab>
                    <v-tab>Register</v-tab>
                </v-tabs>
                <div v-if="whichtab == 0">
                    <login />
                </div>
                <div v-if="whichtab == 1">
                    <register />
                </div>
              </div>                      
            </v-col>
          </v-row>
        </v-col>  
        <v-col cols="12" sm="3" align="right">
            <div v-if="window.width >= 1024"><img style="border:1px dotted gray" src="../assets/onevone.png" /></div>
        </v-col>
      </v-row>
    </v-container>     
  </div>
</template>
<script>
import Login from '../components/Login';
import Register from '../components/Register';

export default {
    name: 'Auth',
    watch:{
      $route(){
        this.action = this.$route.query.action;
        if(this.action == 'login')
          this.whichtab = 0;
        else if(this.action == 'register')  
          this.whichtab = 1;
      }   
    },
    components:{ 
       login: Login,
       register: Register,
    },
    data(){
      return{
        action:"",     
        whichtab:null, 
        window: {
            width: 0,
            height: 0
        }
       }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    created(){
      window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.action = this.$route.query.action;
        if(this.action == 'login')
          this.whichtab = 0;
        else if(this.action == 'register')  
          this.whichtab = 1;
    } 
}    
</script>
<style scoped>
 >>> .v-tab {
    color: #383838 !important;
    font-size: 20px !important;
}
>>> .v-tab:before, .v-tabs-slider, >>> .v-tabs-slider{
    background-color: #383838 !important;
}
</style>