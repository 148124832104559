import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Home from '../views/Home.vue'
import Logout from '../components/Logout';
import Auth from '../views/Auth';
import Dashboard from '../views/Dashboard';
import PersonalInformation from '../views/Personalinformation';
import Changepassword from '../views/Changepassword';
import Tipping from '../views/Tipping';
import Playersladder from '../views/Playersladder';
import Myscorecard from '../views/Myscorecard';
import Faq from '../views/Faq.vue';
import Terms from '../views/Termandcondition';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/terms-and-condition',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: Changepassword,
    meta: {
        title:'Change Password',
        requiresAuth: true,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
        title:'Dashboard',
        requiresAuth: true,
    }
  },
  {
    path: '/personal-information',
    name: 'personal',
    component: PersonalInformation,
    meta: {
        title:'Personal Information',
        requiresAuth: true,
    }
  },
  {
    path: '/tipping/:sport',
    name: 'tipping',
    component: Tipping,
    meta: {
        title:'Tipping',
        requiresAuth: true,
    }
  }, 
  {
    path: '/players-ladder/:sport',
    name: 'playersladder',
    component: Playersladder,
    meta: {
        title:'Players Ladder',
        requiresAuth: true,
    }
  }, 
  {
    path: '/myscore-card/:sport',
    name: 'myscorecard',
    component: Myscorecard,
    meta: {
        title:'My Score Card',
        requiresAuth: true,
    }
  }, 
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  }, 
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
})

router.beforeEach((to, from, next) => {
  store.commit('setsiteloader',true);
  if(to.matched.some(record => record.meta.requiresAuth)) 
  {
    if(!store.getters.loggedIn) 
    {  
       if(to.name  == "match")
       {
        store.commit('setActionModal',true);
        store.commit('setActionType','login');
       } 
    } else {
      next();
    }
  } else if(to.matched.some(record => record.meta.requiresVisitor)) {
    
    if(store.getters.loggedIn) {
      next({
        name: 'home'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach(() => {
  setTimeout(() => store.commit('setsiteloader',false), 1000);
})
export default router
