<template>
     <vue-sticky-sidebar :topSpacing="75" class="sidebar" :bottomSpacing="70" containerSelector=".column-section" innerWrapperSelector='sidebar-inner'>
           <div class="teamladder">
              <div class="teamladder-row">
                <div class="teamladder-header">
                  <h4 class="">{{season}} <span v-if="sportname == 'Football'">PREMIER LEAUGE</span><span v-else>{{ sportname }}</span> LADDER</h4>
                </div> 
                <div class="teamladder-body">
                  <ul class="teamladder-body-title">
                    <li class="teamladder-col number" v-if="sportname != 'NFL'">&nbsp;</li>
                    <li class="teamladder-col team">TEAM</li>
                    <li class="teamladder-col pts" v-if="sportname == 'NFL'">W</li>
                    <li class="teamladder-col pts" v-if="sportname == 'NFL'">L</li>
                    <li class="teamladder-col pts" v-if="sportname != 'NFL'">PTS</li>
                    <li class="teamladder-col percent" style="text-align:center">
                      <span v-if="sportname == 'AFL'">%</span>
                      <span v-else>F/A</span>
                    </li>
                    <li class="teamladder-col streak">STREAK</li>
                  </ul>
                  
                    <div v-if="sportname == 'NFL'">
                     <div v-for="(data,keys) in teamladder" :key="keys">
                        <div class="heading-team">
                          <span>{{ data.heading }}</span>
                        </div>
                        <ul class="teamladder-team" v-for="(team,key) in data.teamdata" :key="key">
                         <li class="teamladder-col team">
                          <img class="team-logo" :title="team.name" :alt="team.name" :src="team.logo" />
                          <span class="ng-binding">{{ team.shortname }}</span>
                        </li>
                        <li class="teamladder-col percent">{{ team.win }}</li>
                        <li class="teamladder-col percent">{{ team.lose }}</li>
                        <li class="teamladder-col percent">{{ team.percentage }}</li>
                        <ul class="teamladder-col streak">
                          <li :key="key" :class="{'win': val == 'W','lose': val == 'L', 'draw' : val == 'D','blank': val == '-'}" v-for="(val,key) in team.teamstreak"><a>{{ val }}</a></li>
                        </ul>
                       </ul> 
                    </div>
                  </div>
                  <div v-else>
                    <ul class="teamladder-team" v-for="(team,key) in teamladder" :key="key">
                    <li class="teamladder-col number">{{ team.rank }}</li>
                    <li class="teamladder-col team">
                      <img class="team-logo" :title="team.name" :alt="team.name" :src="team.logo" />
                      <span class="ng-binding">{{ team.shortname }}</span>
                    </li>
                    <li class="teamladder-col pts">{{ team.points }}</li>
                    <li class="teamladder-col percent">{{ team.percentage }}</li>
                    <ul class="teamladder-col streak">
                      <li :key="key" :class="{'win': val == 'W','lose': val == 'L', 'draw' : val == 'D','blank': val == '-'}" v-for="(val,key) in team.teamstreak"><a>{{ val }}</a></li>
                    </ul>
                  </ul>
                  </div>
                  
                </div> 
                <div class="teamladder-footer"></div>
              </div>  
           </div>
        </vue-sticky-sidebar>  
</template>
<script>
import vueStickySidebar from "vue-sticky-sidebar";
export default{
  data(){
    return{
      sportname:'',   
      teamladder:[],
      season:''
    }
  },
  watch:{
    $route(){
      this.sportname = this.$route.params.sport;
      
      this.getTeamStreak();
    }
  },
  components: {
    "vue-sticky-sidebar": vueStickySidebar
  }, 
  created(){
        this.sportname = this.$route.params.sport;
        this.getTeamStreak();
  }, 
  methods:{
    getTeamStreak(){
       this.$store.dispatch("TeamStreak",{sport:this.sportname}).then((response) => {
          this.teamladder = response.data.ladder;
          this.season = response.data.season;
       });
      }, 
  }  
}
</script>