import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(Vuex)
Vue.use(VueAxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
 
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    actiontype:'',
    actionmodal:false,
    userid:null,
    name: '',
    email: '',
    balance: 0,
    inbank:0,
    kels:0,
    userdetails:[],
    avatar:'',
    siteloader:false,
    showdamnnXtra:false,
    damnnXtraBalance: 0,
  },
  getters: {
    damnnXtraBalance(state){
      return state.damnnXtraBalance;
    },
    siteloader(state){
      return state.siteloader;
    },
    loggedIn(state) {
      return state.token !== null;
    },
    getActionmodal(state){
      return state.actionmodal;
    },
    getActionType(state){
      return state.actiontype;
    },
    getUserdetails(state){
      return state.userdetails;
    },
    getAvatar(state){
      return state.avatar; 
    },
    getUserid(state){
       return state.userid;   
    },
    name(state) {
      return state.name;
    },
    email(state) {
      return state.email;
    },
    inbank(state){
      return state.inbank; 
    },
    kels(state){
      return state.kels; 
    },
    balance(state) {
      return state.balance;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    showdamnnXtra(state){
      return state.showdamnnXtra;
    }
  },
  mutations: {
    setshowdamnnXtra(state,payload){
      state.showdamnnXtra = payload;
    },
    setDamnnXtra(state,payload){
      state.damnnXtraBalance = payload;
   },
    setsiteloader(state,token){
      state.siteloader = token; 
    },
    retrieveToken(state, token) {
      state.token = token;
    },
    setActionModal(state,value){
      state.actionmodal = value;
    },
    setActionType(state,value){
      state.actiontype = value;
    },
    setUserid(state,payload){
      state.userid = payload; 
    },
    destroyToken(state) {
      state.token = null;
    },
    setName(state, name) {
      state.name = name;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setInbank(state, balance) {
      state.inbank = balance;
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setKels(state, kels) {
      state.kels = kels;
    },
    setAvatar(state,payload){
      state.avatar = payload;
   },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setUserdetails(state,payload){
      state.userdetails = payload;
   },
  },
  actions: {
    retrieveToken(context, credentials) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
          damnnId: credentials.damnnId
        }).then(response => {
          
          if(response.data.status)
          {
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            resolve(response);
          }
          else
          {
            resolve(response);
          }
          
        }).catch(error => {
          reject(error);
        })
      })
    },
    updateUserDetails(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var userid = context.getters.getUserid;
          Vue.axios.post('users/profileupdate/'+userid,{name:data.name}).then(response => {
            resolve(response);
            context.dispatch("retrieveUserDetails");
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     updateAvtar(context,data){
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
     return new Promise((resolve, reject) => 
     {
      axios.post('user/avatar', data, { headers: {'Content-Type': 'multipart/form-data' }}).then(response => 
       {
         //console.log(response.data.data.filename);
           context.commit("setAvatar",response.data.data.filename);
           resolve(response);
        }).catch(error => {
         reject(error);
        })
     })
   },
     citieslist(context,data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('getcitieslist', {
          countryname: data.countryname,
          stateid:data.stateid
         }).then(response => {
          resolve(response.data);
          //resolve(response);
        }).catch(error => {
          reject(error);
        })
     
      })
    }, 
    changePassword(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var userid = context.getters.getUserid;
          Vue.axios.post('users/profileupdate/'+userid,{password:data.password}).then(response => {
            resolve(response);
            context.dispatch("retrieveUserDetails");
          }).catch(error => {
            reject(error);
          })
        })
      }         
     }, 
     
    retrieveTokenRegister(context, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
          damnnId:credentials.damnnid
        }).then(response => {
          if (response.data.result.access_token) 
          {
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            context.dispatch("retrieveUserDetails");
            resolve(response);
          } else {
            
            const error = {
              response: {
                data: {
                  message: response.data.data.errors.error
                }
              }
            }
            reject(error)
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    register(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/user/create', {
          username   : data.username,
          firstname  : data.firstname,
          lastname   : data.lastname,
          email      : data.email,
          password   : data.password,
          region     : data.region,
          dob        : data.dob,
          gender     : data.gender,
          cityname   : data.cityname,
          suburb     : data.suburb,
          stateid    : data.stateid,
          app_type   : data.app_type
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    retrieveUserDetails(context) 
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('user').then(response => { 

            const name = response.data.data.name;
            const email = response.data.data.email;
            const isAdmin = response.data.role == 1 ? true : false
            
            if(typeof response.data.data.avatar != 'undefined')
            {
                context.commit('setAvatar',response.data.data.avatar);
            }
            else
            {
              context.commit('setAvatar','');
            }
            
            context.commit('setUserid',response.data.data.id);
            context.commit('setName', name);
            context.commit('setEmail', email);
            context.commit('setIsAdmin', isAdmin);
            context.commit('setUserdetails',response.data.data);
            context.dispatch('retrieveBalance');
            
            resolve(response); 
            
          }).catch(error => {
            context.commit('setName', '');
            context.commit('setEmail', '');
            context.commit('setIsAdmin', false);
            reject(error);
          })
        })
      }
    },
    retrieveBalance(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          //var userid = context.getters.getUserid;
          Vue.axios.get('wallet/user-balance').then(response => {
            const balance = response.data.data.balance;
            const inbank  = response.data.data.inbank;
            const kels    = response.data.data.kels;
            context.commit('setBalance', balance);
            context.commit('setInbank', inbank);
            context.commit('setKels', kels);
            resolve(response.data);
          }).catch(error => {
            context.commit('setBalance');
            reject(error);
          })
        })
      }
    },
    retriveDamnnXtraBank(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('fantasy/get-damnn-xtra-bank/'+data.sportname).then(response => {
            if(response.data.status == "success")
            {
              context.commit('setDamnnXtra',response.data.data.damnnxtra);
            }
            resolve(response.data);
          }).catch(error => {
            context.commit('setDamnnXtra');
            reject(error);
          })
        })
      }  
    },
    stateslist(context,data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('getstateslist', {
          countryname: data.countryname,
         }).then(response => {
          resolve(response.data);
          //resolve(response);
        }).catch(error => {
          reject(error);
        })
     
      })
    }, 
    destroyToken(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('logout').then(response => {
            localStorage.removeItem('access_token');
            context.commit('destroyToken');
            context.commit('setUserdetails','');
            resolve(response);
          }).catch(error => {
            localStorage.removeItem('access_token');
            context.commit('destroyToken');
            reject(error);
          })
        })
      }
    },
    joinLeague(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('join-damnntipping-league',{sport:data.sport}).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     getPuchasedLeagues(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('get-damnntipping-league').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     checkLeagueInfo(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('check-damnntipping-league/'+data.sport+"?week="+data.page).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     TeamStreak(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('damnntipping-team-streak/'+data.sport).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }           
     },
     saveMyTips(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('damnntipping-save-tips',{sport:data.sport,selection:data.selection,week:data.week}).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     sendEmail(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('damnntipping-email-myselection/'+data.sport+'/'+data.week).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     getFutureGame(context,data){
      return new Promise((resolve, reject) => {
        Vue.axios.get('damnntipping-future-game/'+data.sport).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
     },
     getMyScore(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('damnntipping-getmyscore/'+data.sportname).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }          
     },
     getPlayersLadder(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('damnntipping-players-ladder/'+data.sportname+'/'+data.page).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }          
     },
     getmyScoreCard(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('damnntipping-myscore-card/'+data.sport).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }          
     }
  },
  modules: {
  }
})
