<template>
  <div class="container">
    <div class="column-section">
      <div class="column--main">
        <div class="ladder-top-header">
           <div class="logo-container same">
             <div class="logo-imgs">
                <img class="vimg v-imageback" height="45" lazy-src="../assets/logo.png" src="../assets/logo.png" />  
             </div>
             <div class="logo-title">
               <h2>Players Ladder <span v-if="sportname == 'Football'">PREMIER LEAUGE</span><span v-else>{{ sportname }}</span></h2>
             </div>
           </div>
           <div class="myrank same" v-if="myinfo.length > 0">
             <div class="rank">Rank {{ myinfo[0].rank ? myinfo[0].rank : 0 }}</div>
             <div class="avatar">
                <img v-if="myinfo[0].avatar != ''" class="avatar" :src="myinfo[0].avatar" />
                <img v-else src="assets/avatar.png" />
             </div>
             <div class="tw"><div>TW</div>{{ myinfo[0].tw }}</div>
             <div class="lw"><div>LW</div>{{ myinfo[0].lw }}</div>
             <div class="score"><div>Score</div>{{ myinfo[0].score }}</div>
           </div>
        </div>   
        <div class="ladder-body">
           <div class="table-th">
             <div class="rank"><span>Rank</span> <span @click="sorting('rank')" class="sorting material-icons">height</span></div>
             <div class="avatar"><span>Avatar</span></div>
             <div class="name"><span>Name</span> <span @click="sorting('name')" class="sorting material-icons">height</span></div>
             <div class="thisweek" title="This Week Score"><span>TW</span> <span @click="sorting('tw')" class="sorting material-icons">height</span></div>
             <div class="lastweek" title="Last Week Score"><span>LW</span> <span @click="sorting('lw')" class="sorting material-icons">height</span></div>
             <div class="score" title="Score"><span>Score</span> <span @click="sorting('score')" class="sorting material-icons">height</span></div>
           </div> 
           <div class="table-body">
             <div class="text-center pt-5 pb-5" v-if="ladder.length == 0">No Data Present Yet</div>
             <div v-else v-for="(val,key) in  ladder" class="table-th" :key="key">
                <div class="rank text-left">{{val.rank}}</div>
                <div class="avatar">
                   <img v-if="val.avatar != ''" class="avatar" :src="val.avatar" />
                   <img v-else src="assets/avatar.png" />
                </div>
                <div class="name">{{ val.name }}</div>
                <div class="thisweek" title="This Week Score">{{ val.tw }}</div>
                <div class="lastweek" title="Last Week Score">{{ val.lw  }}</div>
                <div class="score" title="Score">{{ val.score }}</div>
             </div> 
           </div>
           <infinite-loading  :identifier="infiniteId" @infinite="getPlayersLadder"></infinite-loading>
        </div>    
      </div>
      <div class="column--side hidden-lg">
        <teamladder></teamladder>
      </div>
    </div>
  </div>
</template>
<script>
 import Teamladder from '../components/Teamladder.vue';

 export default{
    name:'Playersladder',
    components: {
    "teamladder": Teamladder
  },  
    data(){
        return{
          page:1,
          infiniteId: +new Date(),
           sportname:'', 
           ladder:[],
           season:'',
           sortedbyASC: true,
           myinfo:[]
        }
    },
    watch:{
        $route(){
          this.page=1;
          this.sportname = this.$route.params.sport;
          this.myinfo = [];
          this.ladder = [];
          this.getPlayersLadder();
          if(this.$route.query.view == 'tips')
          {
            this.viewonly = true;
          }
        }
   },
    created(){
        this.sportname = this.$route.params.sport;
        this.getMyData();
        //this.getPlayersLadder();
    },
    methods:{
     sorting(sortBy)
     {
        if (this.sortedbyASC) {
          this.ladder.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
          this.sortedbyASC = false;
        } else {
          this.ladder.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
          this.sortedbyASC = true;
        }
     },
     getMyData()
     {
      this.$store.dispatch("getMyScore",{sportname:this.sportname}).then((response) => 
      {
        if(response.data)
         {
            this.myinfo.push(response.data);
         }  
      });       
     },

     getPlayersLadder($state){
         this.$store.dispatch("getPlayersLadder",{sportname:this.sportname,page:this.page}).then((response) => {
          if(response.data.length > 0)
         {
           this.page += 1;
           this.overlay = false; 
           
           for(var j = 0; j < response.data.length; j++)
           {
             this.ladder.push(response.data[j]);    
           }
           $state.loaded();
          }
          else
         {
           $state.complete();
         } 
         }) 
     },
    
    }
 }
</script>
<style scoped>
.ladder-top-header{background-color: #383838;border-radius: 0.625rem 0.625rem 0 0;box-shadow: 0 1px 0.25rem rgb(0 0 0 / 24%); padding: 0.75rem; display: flex;}
.same{ display: flex;} 
.logo-container.same{padding-right: 10px;}
.logo-container .logo-title,.logo-container .logo-imgs {display: table-cell; vertical-align: top;}
.logo-container .logo-imgs img { margin-right: 5px;}
.logo-container .logo-title{ padding-left: 0.5rem; vertical-align: middle;}
.logo-title h2{color: white;font-size: 1.5rem;line-height: 1.813rem;font-size: 19px;}
.rank,.avatar, .thisweek,.lastweek,.score{ flex: 0 0 10%;}
.name{ flex: 0 0 50%;}
.ladder-body{box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;border-radius: 0 0 10px 10px;}
.table-th div { text-align: center; border-right: 1px solid #eee;  border-bottom: 1px solid #eee; padding: 5px; display: flex; align-items: center;}
.table-th div.name{text-align: left;}
.table-th { display: flex;}
.table-th div:last-child{border-right: none;}
img.avatar {width: 80%; border-radius: 50%; border: 3px solid #ffcc8d; height: 50px;}
.sorting { font-size: 20px;  font-weight: 100; cursor: pointer;}
.myrank{color: white; margin-left: 10px; justify-content: right;}
.myrank .rank,.myrank .lw,.myrank .tw{width:60px; margin-right: 10px;}
.myrank .avatar{width: 50px; margin-right: 87px;}
@media(max-width:420px){
.myrank.same{display: none;}
.name{ flex: 0 0 31%;}
.table-th{font-size: 12px;}
.thisweek,.lastweek,.score{ flex: 0 0 13%;}
.rank,.avatar{flex: 0 0 15%;}
img.avatar{width: 75%;height: 40px;}
}
</style>