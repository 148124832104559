<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="9" md="9" class="text-center pb-5 mt-2">
      <v-form action="#" @submit.prevent="login">
        <v-card-text class="removespace">
          <div v-if="error" class="mb-2">
            <strong class="red--text">{{errorText}}</strong>
          </div>
          <v-text-field label="Email" name="username" outlined :rules="emailRules" type="text" id="username" v-model="username"/>
          <v-text-field id="password" label="Password" name="password" :rules="passwordRule" outlined @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" v-model="password"/>
        </v-card-text>
        <v-card-actions class="justify-center">
         <v-row dense>
           <v-col col="12" md="12" sm="12">  
             <v-btn type="submit" :disabled="disablebtn" class="button__full x-large" color="primary">Login</v-btn>
             <div class="mt-5">
                 Don't have an account? <a @click="openregister">Create Account</a>
             </div>
             <router-link class="bluecolor" :to="{name: 'reset-password', params:{  }}" >
                 Forgot Password
              </router-link>
           </v-col>
        </v-row>
       </v-card-actions>
      </v-form>
     </v-col> 
  </v-row>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      username: '',
      password: '',
      damnnid:'',
      error: false,
      show1: false,
      errorText: '',
      disablebtn:false,
      emailRules: [
        v => !!v || "Please enter your email address",
        v =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address"
      ],
      passwordRule: [
        v => v.length >= 6 || "Password must be at least 6 characters"
      ],
      damnnIdRule:[
        v => !!v || "Please enter DamnnId",
      ],
      logo: require("@/assets/logo.png")
    };
  },
  methods: {
    openregister(){
       this.$store.commit('setActionModal',true);
       this.$store.commit('setActionType','register');
    },
    login() 
    {
       if(this.username != '' && this.password != '')
       {
        this.disablebtn = true;
         this.$store
        .dispatch("retrieveToken", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$store
            .dispatch("retrieveUserDetails")
            .then((response) => {
              this.disablebtn = false;
              if(typeof response == 'undefined')
              {
                this.error = true;
                this.errorText = 'No user exist';
              }
              else
              {
                  this.$router.push({ name: "dashboard" });
                  this.$store.commit('setActionModal',false);
                   if(typeof response.data.data.userplan != "undefined")
                    {
                      if(response.data.data.userplan != "" && response.data.data.userplan == "Professional")
                      {
                        this.$vuetify.theme.dark = true;
                      }
                      else
                      {
                        this.$vuetify.theme.dark = false;
                      }
                    }   
              }
            })
        })
        .catch(error => {
          this.disablebtn = false;
          this.error = true;
          this.errorText = error.response.data.message;
        });
       }
       else
       {
          this.error = true;
          this.disablebtn = false;
          this.errorText = 'Please enter login details';
       }
    }
  }
};
</script>

<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
.logheading{font-size:24px;font-weight:600; color: black;}
</style>