<template>
  <div class="fill-height">
   <v-app-bar app color="primary" dark  >
     <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="loggedIn"/> 
     <div class="toplogo primary" v-if="!drawer">
      <router-link :to="{name:'home'}">
        <img class="vimg v-imageback" height="45" lazy-src="../assets/logo.png" src="../assets/logo.png" />  
      </router-link>  
     </div> 
     <v-spacer></v-spacer>
     <div class="topbalance marginright30" v-if="showdamnnXtra" >
         <span class="toptitle">DamnN Xtra</span>
         <span class="balance">
          <span class="displaycurr white--text" v-html="currency(getdamnnxtraBalance)"></span></span>
       </div>
     <div class="topbalance marginright30 cursor notformobile" v-if="loggedIn" @click="opendeposit">
         <span class="toptitle" >DamnN Bank</span>
         <span class="balance" ><span class="d-flex" v-html="currency(getinbankbalance)"></span></span>
       </div>
       <div class="topbalance marginright30 notformobile" v-if="loggedIn">
         <span class="toptitle">PP Bank</span>
         <span class="balance "><span class="displaycurr d-flex" v-html="currency(getbalance)"></span></span>
       </div>
       <div class="topbalance marginright10 notformobile" v-if="loggedIn">
         <div class="toptitle">Kels</div>
         <span class="balance"><span class="displaycurr" v-html="getkels"></span></span>
       </div>
       <v-btn v-if="!loggedIn" to="/auth?action=login" class="btns" outlined rounded color="white">Login</v-btn>
       <v-btn v-if="!loggedIn" to="/auth?action=register" class="btns" outlined rounded color="white">Register</v-btn>
       <v-avatar class="topavatar" v-if="loggedIn"><img :src="getavatar" ></v-avatar>
       <span class="topname" v-if="loggedIn">{{ name }}</span>
        <v-menu bottom offset-y min-width="200px" v-if="loggedIn">
          <template v-slot:activator="{ on, attrs }">
             <span class="material-icons" v-bind="attrs" v-on="on" >keyboard_arrow_down</span>
          </template>
          <v-list class="topbarmenu">
            <v-list-item><router-link :to="{name: 'personal'}">Personal Information</router-link></v-list-item> 
            <v-list-item v-if="checkplan!=''"><a @click="unsubscribeplan">{{checkplan}} Unsubscribe</a></v-list-item> 
            <v-list-item><router-link :to="{name: 'changepassword'}">Change Password</router-link></v-list-item> 
            <v-list-item><a @click="opendeposit">Deposit</a></v-list-item> 
            <v-list-item><router-link :to="{name: 'logout'}">Logout</router-link></v-list-item>
         </v-list>
       </v-menu>
    </v-app-bar>
    <v-navigation-drawer  v-model="drawer" app class="sidebarcss" v-if="loggedIn"   >
    <div class="sidebarlogo primary">
      <img class="vimg v-imageback" :src="logo"  />
  </div>
      <v-list nav dense>
        <v-list>
          <v-list-item class="displayonmob">
            <div class="topbalance marginright30 ">
               <span class="toptitle" @click="opendeposit">DamnN Bank</span>
               <span class="balance" @click="opendeposit"><span class="displaycurr " v-html="currency(getinbankbalance)"></span></span>
             </div>
             <div class="topbalance marginright30">
               <span class="toptitle">PP Bank</span>
               <span class="balance"><span class="displaycurr " v-html="currency(getbalance)"></span></span>
             </div>
              <div class="topbalance marginright10">
               <div class="toptitle">Kels</div>
               <span class="balance"><span class="displaycurr " v-html="getkels"></span></span>
             </div>
           </v-list-item>
          <v-list-item link :to="{ name: 'home' }"  :class="'/' === $route.path ? 'highlighted' : ''">
            <v-list-item-icon><span class="material-icons">home</span></v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item link :to="{ name: 'dashboard' }"  :class="'/dashboard' === $route.path ? 'highlighted' : ''">
            <v-list-item-icon><span class="material-icons">dashboard</span></v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          
          <v-list  expand dense>
            <v-list-group prepend-icon="mdi-run"   class="otherhigh" no-action>
              <template v-slot:activator>
                 <v-list-item-title>Choose Sport</v-list-item-title>
              </template>
              <template>
                <v-list-item link :to="{ name: 'dashboard' }" v-if="Aflpurchased == 0" >
                  <v-list-item-icon><img src="../assets/afl.png" class="smalllogo" /></v-list-item-icon>
                  <v-list-item-title>AFL</v-list-item-title>
                </v-list-item>     
                <v-list-group v-else  no-action  class="innergrouplist" prepend-icon="$expand">
                  <template v-slot:activator>
                    <v-list-item-icon><img src="../assets/afl.png" class="sideicon"></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>AFL</v-list-item-title></v-list-item-content>             
                  </template>
                  <v-list-item :to="{ name: 'tipping', params: {sport:'AFL'}}">
                    <v-list-item-icon><span class="material-icons">groups</span></v-list-item-icon>
                    <v-list-item-title>My Team Tips</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'playersladder', params: {sport:'AFL'}}">
                    <v-list-item-icon><img src="../assets/ladder.png"/></v-list-item-icon>
                    <v-list-item-title>Players Ladder</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'myscorecard', params: {sport:'AFL'}}">
                    <v-list-item-icon><img src="../assets/scoresheet.png"></v-list-item-icon>
                    <v-list-item-title>My Score Card</v-list-item-title>
                  </v-list-item>     
                </v-list-group>

                <v-list-item link :to="{ name: 'dashboard' }"   v-if="Nrlpuchased == 0">
                  <v-list-item-icon><img src="../assets/nrl.png" class="smalllogo" /></v-list-item-icon>
                  <v-list-item-title>NRL</v-list-item-title>
                </v-list-item>     
                <v-list-group v-else  no-action  class="innergrouplist" prepend-icon="$expand">
                  <template v-slot:activator>
                    <v-list-item-icon><img src="../assets/nrl.png" class="sideicon"></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>NRL</v-list-item-title></v-list-item-content>             
                  </template>
                  <v-list-item :to="{ name: 'tipping', params: {sport:'NRL'}}">
                    <v-list-item-icon><span class="material-icons">groups</span></v-list-item-icon>
                    <v-list-item-title>My Team Tips</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'playersladder', params: {sport:'NRL'}}">
                    <v-list-item-icon><img src="../assets/ladder.png"/></v-list-item-icon>
                    <v-list-item-title>Players Ladder</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'myscorecard', params: {sport:'NRL'}}">
                    <v-list-item-icon><img src="../assets/scoresheet.png"></v-list-item-icon>
                    <v-list-item-title>My Score Card</v-list-item-title>
                  </v-list-item>     
                </v-list-group>
                
                <v-list-item link :to="{ name: 'dashboard' }"   v-if="Footballpurchased == 0">
                  <v-list-item-icon><img src="../assets/epl.png" class="smalllogo" /></v-list-item-icon>
                  <v-list-item-title>Football</v-list-item-title>
                </v-list-item>     
                <v-list-group v-else  no-action  class="innergrouplist" prepend-icon="$expand">
                  <template v-slot:activator>
                    <v-list-item-icon><img src="../assets/epl.png" class="sideicon"></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>EPL</v-list-item-title></v-list-item-content>             
                  </template>
                  <v-list-item :to="{ name: 'tipping', params: {sport:'Football'}}">
                    <v-list-item-icon><span class="material-icons">groups</span></v-list-item-icon>
                    <v-list-item-title>My Team Tips</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'playersladder', params: {sport:'Football'}}">
                    <v-list-item-icon><img src="../assets/ladder.png"/></v-list-item-icon>
                    <v-list-item-title>Players Ladder</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'myscorecard', params: {sport:'Football'}}">
                    <v-list-item-icon><img src="../assets/scoresheet.png"></v-list-item-icon>
                    <v-list-item-title>My Score Card</v-list-item-title>
                  </v-list-item>     
                </v-list-group>
        
                 <v-list-item link :to="{ name: 'dashboard' }"   v-if="Nflpurchased == 0">
                  <v-list-item-icon><img src="../assets/nfl.png" class="smalllogo" /></v-list-item-icon>
                  <v-list-item-title>NFL</v-list-item-title>
                </v-list-item>     
                <v-list-group v-else  no-action  class="innergrouplist" prepend-icon="$expand">
                  <template v-slot:activator>
                    <v-list-item-icon><img src="../assets/nfl.png" class="sideicon"></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>NFL</v-list-item-title></v-list-item-content>             
                  </template>
                  <v-list-item :to="{ name: 'tipping', params: {sport:'NFL'}}">
                    <v-list-item-icon><span class="material-icons">groups</span></v-list-item-icon>
                    <v-list-item-title>My Team Tips</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'playersladder', params: {sport:'NFL'}}">
                    <v-list-item-icon><img src="../assets/ladder.png"/></v-list-item-icon>
                    <v-list-item-title>Players Ladder</v-list-item-title>
                  </v-list-item>     
                  <v-list-item :to="{ name: 'myscorecard', params: {sport:'NFL'}}">
                    <v-list-item-icon><img src="../assets/scoresheet.png"></v-list-item-icon>
                    <v-list-item-title>My Score Card</v-list-item-title>
                  </v-list-item>     
                </v-list-group>
              

             </template>
             </v-list-group>
           </v-list>  
           <v-list-item link :to="{ name: 'Faq' }"  :class="'/' === $route.path ? 'highlighted' : ''">
            <v-list-item-icon><span class="material-icons">contact_support</span></v-list-item-icon>
            <v-list-item-title>FAQ's</v-list-item-title>
          </v-list-item>
          <v-list-item link :to="{ name: 'Terms' }"  :class="'/terms-and-condition' === $route.path ? 'highlighted' : ''">
            <v-list-item-icon><span class="material-icons">gavel</span></v-list-item-icon>
            <v-list-item-title>Terms & Conditions</v-list-item-title>
          </v-list-item>
         </v-list> 
       </v-list>  
      </v-navigation-drawer>
   <v-main>
     <router-view/>
     <Footer />
   </v-main>
 
   <!---Deposite-->
   <Deposite :dialogdeposit.sync="dialogdeposit" ></Deposite>
   <!---->
   <v-overlay :value="siteloader">
       <img class="loader" src="../assets/preloader.gif" />
   </v-overlay>
</div>
</template>
<script>
import Vue from 'vue'
Vue.mixin({
  methods: {
   
    currencysymbol: function(){
       var symbol   = "$";
       return symbol;      
    },
    
    currency: function (currency="") {
      var newprice = "";
      if(this.$store.getters.loggedIn) 
      {
         var symbol   = "$";
         newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+currency+"</div>";
       } 
       return newprice; 
    },
  }
})
  
  import { mapGetters } from 'vuex'
  import Deposite from './Deposite.vue';
  import Footer from '../views/Footer.vue';

  export default {
    name: 'Master',
    components:{ 
       Deposite,
       Footer
    },
    data () {
    return {
      view: {
        topOfPage: true
      },
      drawer: false,
      dialogwithdraw:false,
      dialogdeposit:false,
      hideallthing:false,
      unsubscribedialog:false,
      packlanguage:'',
      btndisable:false,
      snackbar:false,
      loader:false,
      message:'',
      logo:require('@/assets/logo.png'),
      Aflpurchased:0,
      AflEntry:0,
      Nrlpuchased:0,
      NrlEntry:0,
      Footballpurchased:0,
      FootballEntry:0,
      Nflpurchased:0,
      NflEntry:0,
     }
    },
    created(){
      this.$root.$refs.Master = this;
      this.$store.dispatch("retrieveUserDetails");
      this.getPurchasedLeagues();
    },
    computed:
    {
      ...mapGetters({
       popupmodel:'getActionmodal',
       screen:'getActionType',
       siteloader:'siteloader',
       showdamnnXtra:'showdamnnXtra'
     }),
     getdamnnxtraBalance(){
      return this.$store.getters.damnnXtraBalance; 
     },
     showforscreen(){
         var mysendvalue = false; 
         var checkarray  = ["home"];
         
         if( checkarray.indexOf(this.$route.name) !== -1)
         {
           mysendvalue = true;
         }
         return mysendvalue;
     },  
     checkplan(){
        var sendval = '';
        if(typeof this.$store.getters.getUserdetails.userplan != "undefined")
        {
          sendval = this.$store.getters.getUserdetails.userplan;
        }
        return sendval;
     },
     loggedIn() {
      return this.$store.getters.loggedIn;
     },
     getavatar(){
      var avatar = require("@/assets/avatar.png");
     
         if(this.$store.getters.getAvatar != '' && this.$store.getters.getAvatar != null) 
        {
            avatar = this.$store.getters.getAvatar;
        } 
         return avatar;
    },
    name() {
      return this.$store.getters.name;
    },
     getinbankbalance(){
      return this.$store.getters.inbank;
     },
     getbalance() {
      return this.$store.getters.balance;
     },
     getkels(){
      return this.$store.getters.kels;
     }
    },
    methods:{
      closemenu(){
         this.drawer = false;
      },
       getPurchasedLeagues(){
       this.$store.dispatch("getPuchasedLeagues").then((response) => {
        this.$store.commit('setsiteloader',false);
        this.hideallthing = false;
        if(typeof response != "undefined")
         {
          if(response.status == "success")
         {
           if(response.data.AFL.purchased == 1)
           {
             this.Aflpurchased = 1;
           }
           if(response.data.AFL.entry == 1)
           {
             this.AflEntry = 1;
           }
           if(response.data.NRL.purchased == 1)
           {
             this.Nrlpuchased = 1;
           }
           if(response.data.NRL.entry == 1)
           {
             this.NrlEntry = 1;
           }
           if(response.data.Football.purchased == 1)
           {
             this.Footballpurchased = 1;
           }
           if(response.data.Football.entry == 1)
           {
             this.FootballEntry = 1;
           }
           if(typeof response.data.NFL != "undefined")
           {
            if(response.data.NFL.purchased == 1)
           {
             this.Nflpurchased = 1;
           }
           if(response.data.NFL.entry == 1)
           {
             this.NflEntry = 1;
           }
           }
           
         }
         }
       });
    },
      popup(type){
         this.$store.commit('setActionModal',true);
         this.$store.commit('setActionType',type);
      },
      closeDialog(){
        this.$store.commit('setActionModal',false);
        //this.$store.commit('setActionType','');
      },
      opendeposit(){
        this.dialogdeposit = true;
        this.error = false;
        this.errorText = '';
      },
    }
  }
</script>
 <style>
 .cursor{cursor: pointer;}
.themecustbtn{text-transform: capitalize;}
.fix-height{min-height: 245px;}
.loader{width: 200px;}
span.sidetext {position: absolute;font-size: 40px;font-weight: bold;margin-top: -15px;margin-left: 2px;}
.btns{margin-left: 10px; }
.theme--light .anchor{color: white; text-decoration: none;}
.marginright40 { margin-right: 40px;}
.marginright30 { margin-right: 30px;}
.marginright10 { margin-right: 10px;}
.topbalance { font-size: 14px;}
.topbarmenu a {    color: #010101!important;    font-size: 14px;    text-decoration: none;}
.topavatar {  margin-right: 5px; width: 30px!important;    height: 30px!important;}
span.proclass {    font-size: 36px;    padding-left: 5px;}
.displayonmob{display: none;}
.theme--dark.v-application a{color:white;text-decoration: none;}
.theme--dark.v-application .topbarmenu a,.theme--dark.v-application .VueCarousel-navigation-button{color: white !important;}
.background-loader{  position: fixed;  z-index: 300;    width: 100%;  height: 100%;}
.loader{  position: fixed;  z-index: 301;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);  height: 200px;  width: 200px;  overflow: hidden;  text-align: center;}
.spinner{  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);  z-index: 303;  border-radius: 100%;  border-left-color: transparent !important;  border-right-color: transparent !important;}
.spinner1{  width: 100px;  height: 100px;  border: 10px solid #fff;  animation: spin 1s linear infinite;}
.spinner2{  width: 70px;  height: 70px;  border: 10px solid #fff;  animation: negative-spin 2s linear infinite;}
.spinner3{  width: 40px;  height: 40px;  border: 10px solid #fff;  animation: spin 4s linear infinite;}
.loader-text {  position: relative;  top: 75%;  color: #fff;  font-weight: bold;}
.sidebarlogo{text-align:center;height: 64px; line-height: 64px;}
.sidebarlogo img { width: 54%; }
.fix-height{min-height: 448px;}
.highlighted{ border-left: 2px solid #383838;border-radius: 0;background: transparent;}
.highlighted span,.highlighted .v-list-item__title{color:#383838 !important;}
.v-list-item--link::before {background-color: #fff !important;}
.v-list--nav{padding-left: 0;}
.displayonmob{display: none;}
.toptitle {font-size: 10px; color: #fff;}
.displaycurr{display: flex;}
@media(max-width:767px){
.notformobile{display: none;}
.sidebarlogo{height: 54px;}
.displayonmob{display: flex;border-bottom: 1px solid #007bce;}
.displayonmob .toptitle { color: #000;}
.balance {
  color: #247cdd;
  font-size: 16px;
}
}
@keyframes spin {
  0%{
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

@keyframes negative-spin {
  0%{
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(-360deg);
  }
}
.v-navigation-drawer .v-list {
  padding: 0;
}
.innergrouplist .v-list-item__icon{padding-right:6px !important}
.innergrouplist .v-list-item__icon.v-list-group__header__append-icon{padding-right:0px !important}
.innergrouplist .v-list-item__icon.v-list-group__header__prepend-icon i{display: none;}
.innergrouplist .v-list-group__header__prepend-icon{margin-right:2px !important}
.sideicon, .sideiconnew {
  
  margin-right: 5px;
}
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 13px;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 45px;
}
.streak li.win>a { color: #009444;}
.streak li.lose>a {color: #d00;}
.streak li.blanke>a {color: #000;}
.streak li.draw >a {color: #152f69;}
.teamladder .team-logo {width: 21px; height: 15.4px;margin-right: 4px;}
.streak li.win,.streak li.lose,.streak li.draw,.streak li.blank {font-weight: 600;justify-content: center;}
.streak li {cursor: pointer;margin: 2px;border: 1px solid #d8d8d8;border-radius: 2px;text-align: center;padding-left: 0;width: 18px;height: 20px;display: flex;align-items: center;justify-content: flex-start;}
.teamladder-team {height: 36px;background-color: #f9f9fb;margin: 0;padding: 0;overflow: hidden; display: flex; padding-left: 8px !important;border-bottom: 1px solid #edeef0;}
.teamladder .teamladder-team li, .sportladder .teamladder-team ul {font-size: 12px;align-self: center;}
.teamladder li {width: 100%;list-style-type: none;float: left; padding: 0 0 0;}
.teamladder .streak {padding: 0;display: flex;width: 150px;}
.heading-team{  height: 36px; background-color: #dcdddf; margin: 0; padding: 0; overflow: hidden; display: flex; padding-left: 8px; border-bottom: 1px solid #edeef0;}
.heading-team span{display: block; align-self: center; font-size: 12px; font-weight: bold;}
.teamladder li.streak {width: 150px;}
.teamladder li.percent {width: 30px;text-align: right;margin-left: 6px;margin-right: 10px;color: #6c6e6f;}
.teamladder li.pts {width: 30px;text-align: right;margin-right: 13px;}
.teamladder li.team {width: 90px;display: flex;}
.teamladder-body-title .teamladder-col {font-weight: 600;}
.teamladder .teamladder-col {list-style-type: none;font-size: 11px;align-self: center;}
.teamladder li.number {width: 18px;}
.teamladder-body-title{display: flex;margin: 0;padding: 0;background-color: #fff;height: 22px;padding-left: 8px;}
.teamladder-body{ padding: 0;}
.inner-wrapper-sticky{box-shadow:rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;border-radius: 10px;}
.teamladder-row {margin: 0;margin-bottom: 0.5rem;}
.teamladder-header{display: flex;align-items: center;padding-left: 14px;height: 44px;width: 100%;border-bottom: 1px solid #edeef0;}

@media screen and (max-width: 1024px){
.hidden-lg {  display: none!important;}
.column--main{max-width: 100% !important;}
}
</style>