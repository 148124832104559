<template>
  <div class="container fix-height">
    <div class="column-section">
      <div class="column--main">
        <div class="tipping-match-container">
          <div class="tipping-header">
             <div class="tipping-header-top-section pb-5">
                <h1>Tipping</h1>
                <div class="justify-end">
                  <div class="options">
                    <v-btn color="white" class="themecustbtn mr-5" @click="readrule = true" rounded>Read Rules</v-btn>  
                    <v-btn color="blue" class="themecustbtn white--text" @click="howtoplay = true" rounded>How to play</v-btn>  
                  </div>
                </div>
             </div>
             <div class="tipping-header-bottom-section" v-if="roundloaded">
               <div class="prev-xs">
                 <a @click="changepage('prev')" class="navigation " v-if="previouspage > 0">
                  <span class="material-icons">navigate_before</span>
                   <span>Round {{ previouspage }}</span> 
                 </a>
                 <a @click="changepage('prev')" class="navigation " v-if="specialcase > 0">
                  <span class="material-icons">navigate_before</span>
                   <span>Opening Round</span> 
                 </a>
                 <a v-if="specialcase == 0 && previouspage <= 0" class="blankhref">&nbsp;</a>
               </div>
               <div class="navigation-option">{{ sportname }}  {{ roundname }}</div>
                 <a @click="changepage('next')" class="navigation navigation-option next" v-if="!hidenavigation">
                   <span class="">Round {{ nextpage }}</span>
                   <span class="material-icons">navigate_next</span>
                 </a>
                 <a v-else class="blankhref">&nbsp;</a>
             </div>
          </div>
        </div>
        <div class="match-listing" v-if="schedule.length > 0">
          <div v-if="sportname == 'NFL'">
            <div class="single-match" v-for="(val,key) in schedule" :key="key" :ref="'match'+val.game">
            <div class="part-head">
                <span class="match-venue">Venue : {{ val.venue }} </span>
            </div>
            <div class="part-team">
              <!---->
              <div class="single-team awayteam" :class="{'makedisable' :  !val.awayselected && val.homeselected }" :id="val.awayteamid">
                <div class="logo"><img :src="val.awayteamlogo" :title="val.awayteamname" :alt="val.awayteamname"></div>
                <span class="team-name">{{val.awayteamshortname}}</span>
                <div class="text-center d-flex justify-center">
                  <div v-if="viewonly && !val.homeselected && !val.awayselected &&  val.result == ''">
                    <span class="no-tip">No Tip</span>
                  </div>
                  <div v-else-if="viewonly && !val.homeselected && val.awayselected &&  val.result == ''">
                    <span class="my-tip">Your Tip</span>
                  </div>
                  <div v-else-if="viewonly && val.homeselected && !val.awayselected &&  val.result == ''">
                    <span class="">&nbsp;</span>
                  </div>
                  <div v-else>
                    <div v-if="val.matchrunning == 0">
                      <div v-if="val.result == '' && val.isCompleted == 0">
                        <input  type="radio" @click="makeselect(val.game,val.awayteamid,'awayteam')" :name="'select'+[val.game]" :value="val.awayteamid" :checked="val.awayselected"  /> 
                        <div class="progressbar">
                          <v-progress-linear :value="val.awayselectionper" color="red" height="15" reverse rounded>
                            <template v-slot="{ value }"><span class="progval">{{ Math.ceil(value) }}%</span></template>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="val.matchdraw == 1"><span class="material-icons correct">done</span></div>
                        <div v-else> 
                          <span v-if="val.awayselected && val.result == 'W'" class="material-icons correct">done</span>
                          <span v-if="val.awayselected && val.result == 'L'" class="material-icons wrong">close</span>
                          <span v-if="!val.awayselected && val.result != ''">&nbsp;</span>
                        </div>  
                      </div>
                  </div>
                 </div>
                </div>
              </div>
              <!---->
              <div class="match-details">
                <div class="match-time">
                  <span class="date">{{ val.date }}</span><span class="time">{{ val.time }}</span>
                </div>
                <span class="versase">vs</span>
                <div class="tipselected" v-if="val.homeselected || val.awayselected">Tip Entered</div>
              </div>
              <!---->
              <div class="single-team hometeam" :class="{'makedisable' :  !val.homeselected && val.awayselected }" :id="val.hometeamid">
                <div class="logo">
                   <img :src="val.hometeamlogo" :title="val.hometeamname" :alt="val.hometeamname">
                </div>
                <span class="team-name">{{ val.hometeamshortname }}</span>
                <div class="text-center d-flex justify-center">
                  <div v-if="viewonly && !val.homeselected && !val.awayselected && val.result == ''">
                     <span class="no-tip">No Tip</span>
                  </div>
                  <div v-else-if="viewonly && val.homeselected && !val.awayselected && val.result == ''">
                    <span class="my-tip">Your Tip</span> 
                  </div>
                  <div v-else-if="viewonly && !val.homeselected && val.awayselected && val.result == ''">
                    <span class="">&nbsp;</span>
                  </div>
                  <div v-else>
                    <div v-if="val.matchrunning == 0 ">
                      <div v-if="val.result == '' && val.isCompleted == 0"> 
                      <input  type="radio" @click="makeselect(val.game,val.hometeamid,'hometeam')" :name="'select'+[val.game]" :value="val.hometeamid" :checked="val.homeselected"  /> 
                      <div class="progressbar">
                        <v-progress-linear :value="val.homeselectionper" color="blue" height="15" rounded >
                          <template v-slot="{ value }"><span class="progval">{{ Math.ceil(value) }}%</span></template>
                        </v-progress-linear>
                      </div> 
                    </div>  
                    <div v-else>
                      <div v-if="val.matchdraw == 1"> <span class="material-icons correct">done</span></div>
                      <div v-else>
                        <span v-if="val.homeselected && val.result == 'W'" class="material-icons correct">done</span>
                        <span v-if="val.homeselected && val.result == 'L'" class="material-icons wrong">close</span>
                        <span v-if="!val.homeselected && val.result != ''">&nbsp;</span>
                      </div>
                    </div>
                    </div> 
                  </div>
                </div>
              </div> 
              <!---->
            </div>
          </div>
          </div>
          <div v-else>
            <div class="single-match" v-for="(val,key) in schedule" :key="key" :ref="'match'+val.game">
            <div class="part-head">
                <span class="match-venue">Venue : {{ val.venue }} </span>
            </div>
            <div class="part-team">
              <div class="single-team hometeam" :class="{'makedisable' :  !val.homeselected && val.awayselected }" :id="val.hometeamid">
                <div class="logo">
                   <img :src="val.hometeamlogo" :title="val.hometeamname" :alt="val.hometeamname">
                </div>
                <span class="team-name">{{ val.hometeamshortname }}</span>
                <div class="text-center d-flex justify-center">
                  <div v-if="viewonly && !val.homeselected && !val.awayselected && val.result == ''">
                     <span class="no-tip">No Tip</span>
                  </div>
                  <div v-else-if="viewonly && val.homeselected && !val.awayselected && val.result == ''">
                    <span class="my-tip">Your Tip</span> 
                  </div>
                  <div v-else-if="viewonly && !val.homeselected && val.awayselected && val.result == ''">
                    <span class="">&nbsp;</span>
                  </div>
                  <div v-else>
                    <div v-if="val.matchrunning == 0 ">
                      <div v-if="val.result == '' && val.isCompleted == 0"> 
                      <input  type="radio" @click="makeselect(val.game,val.hometeamid,'hometeam')" :name="'select'+[val.game]" :value="val.hometeamid" :checked="val.homeselected"  /> 
                      <div class="progressbar">
                        <v-progress-linear :value="val.homeselectionper" color="blue" height="15" rounded >
                          <template v-slot="{ value }"><span class="progval">{{ Math.ceil(value) }}%</span></template>
                        </v-progress-linear>
                      </div> 
                    </div>  
                    <div v-else>
                      <div v-if="val.matchdraw == 1"> <span class="material-icons correct">done</span></div>
                      <div v-else>
                        <span v-if="val.homeselected && val.result == 'W'" class="material-icons correct">done</span>
                        <span v-if="val.homeselected && val.result == 'L'" class="material-icons wrong">close</span>
                        <span v-if="!val.homeselected && val.result != ''">&nbsp;</span>
                      </div>
                    </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="match-details">
                <div class="match-time">
                  <span class="date">{{ val.date }}</span><span class="time">{{ val.time }}</span>
                </div>
                <span class="versase">vs</span>
                <div class="tipselected" v-if="val.homeselected || val.awayselected">Tip Entered</div>
              </div>
              <div class="single-team awayteam" :class="{'makedisable' :  !val.awayselected && val.homeselected }" :id="val.awayteamid">
                <div class="logo"><img :src="val.awayteamlogo" :title="val.awayteamname" :alt="val.awayteamname"></div>
                <span class="team-name">{{val.awayteamshortname}}</span>
                <div class="text-center d-flex justify-center">
                  <div v-if="viewonly && !val.homeselected && !val.awayselected &&  val.result == ''">
                    <span class="no-tip">No Tip</span>
                  </div>
                  <div v-else-if="viewonly && !val.homeselected && val.awayselected &&  val.result == ''">
                    <span class="my-tip">Your Tip</span>
                  </div>
                  <div v-else-if="viewonly && val.homeselected && !val.awayselected &&  val.result == ''">
                    <span class="">&nbsp;</span>
                  </div>
                  <div v-else>
                    <div v-if="val.matchrunning == 0">
                      <div v-if="val.result == '' && val.isCompleted == 0">
                        <input  type="radio" @click="makeselect(val.game,val.awayteamid,'awayteam')" :name="'select'+[val.game]" :value="val.awayteamid" :checked="val.awayselected"  /> 
                        <div class="progressbar">
                          <v-progress-linear :value="val.awayselectionper" color="red" height="15" reverse rounded>
                            <template v-slot="{ value }"><span class="progval">{{ Math.ceil(value) }}%</span></template>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="val.matchdraw == 1"><span class="material-icons correct">done</span></div>
                        <div v-else> 
                          <span v-if="val.awayselected && val.result == 'W'" class="material-icons correct">done</span>
                          <span v-if="val.awayselected && val.result == 'L'" class="material-icons wrong">close</span>
                          <span v-if="!val.awayselected && val.result != ''">&nbsp;</span>
                        </div>  
                      </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
          </div>          
          <div class="savedata text-center" v-if="!viewonly">
            <v-btn rounded color="#F90505" class="white--text" @click="confirmTips">Submit Your tip</v-btn>
          </div>
          <div class="savedata text-right" v-else>
            <div class="d-flex justify-space-between">
              <div class="d-flex ">
                <v-btn color="#f90505" rounded class="ma-2 white--text" :disabled="disablebtn" @click="gotomainpage">
                  Edit Tips
                </v-btn>
              </div>
              <div class="d-flex ">
                <v-btn color="black" rounded class="ma-2 white--text" :disabled="disablebtn" @click="sendemail">
                  <v-icon right dark>mdi-email</v-icon>&nbsp; &nbsp;Email Tips
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noschedule">No Schedule Available</div>
      </div>
      <div class="column--side hidden-lg">
        <teamladder></teamladder>
      </div>
    </div>  
    <v-dialog v-model="savemodel"  max-width="400">
       <v-card v-if="showwarning">
        <v-card-text class="pt-5 pb-5">
           <div class="text-center"><h3>Please select all matches.</h3></div>
          </v-card-text>
       </v-card>
       <v-card v-else> 
         <v-card-text class="pt-5 pb-5">
           <div>Are you sure that you would like to confirm your tips ?</div>
          </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn color="red darken-1" :disabled="disablebtn" text @click="savemodel = false">No</v-btn>
           <v-btn color="primary darken-1" :disabled="disablebtn" text @click="saveTips">Yes</v-btn>
         </v-card-actions>  
       </v-card>  
       <v-progress-linear indeterminate color="red darken-2" v-if="showprogress"></v-progress-linear>
     </v-dialog>
     <v-dialog class="playhelp" transition="dialog-bottom-transition" v-model="readrule" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <div class="headertop"><h1 class="scoringHeader">Scoring &amp; Tipping Information</h1></div>
          <p>This is a guide only, please view full rules of play below.</p>
        </v-toolbar>
        <v-card-text>
          <div class="topic-container pt-5">
            <h4>What you need to know about Tipping</h4>
            <div class="topic-expand">
              <ul>
              <li>Tips for each game can be entered into at any time before the first game of the round but once confirmed they cannot be changed.</li>
              <li>Under no circumstances will tips be accepted after the first game of the round has started.</li>
              <li>If tippers do not enter tips for a game, round or join after the season has commenced they will be allocated the away teams for each game. See FAQ's for more detail.</li>
              <li>Damnn Sports Tips is for only the regular season games.</li>
              </ul>	
              </div>
              </div>  
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="readrule = false">Close</v-btn>
        </v-card-actions>
     </v-card>
    </v-dialog>  
    <v-dialog class="playhelp" transition="dialog-bottom-transition" v-model="howtoplay" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <div class="headertop pt-3"><h1 class="scoringHeader">How to Play</h1></div>
        </v-toolbar>
        <v-card-text>
          <div class="mt-5">
            <h3>Pick the result</h3>
            <p class="mt-2">Pick the outright winner across every match in each round.</p>
          </div>
        </v-card-text>
      </v-card>
   </v-dialog>       
  </div>
</template>
<script>
import Teamladder from '../components/Teamladder.vue';

export default{
  name:"Tipping",
  components: {
    "teamladder": Teamladder
  },
  data(){
    return{
      roundname: '',
      specialcase:0,
      sportname:'',
      schedule:[],
      teamladder:[],
      roundloaded:true,
      pageno:0,
      currentweek:0,
      previouspage:0,
      nextpage:0,
      selection:[],
      savemodel:false,
      showprogress:false,
      disablebtn:false,
      howtoplay:false,
      readrule:false,
      totalmatches:0,
      showwarning:false,
      viewonly:false,
      hidenavigation:true,
      season:''
    }
  },  
  watch:{
    $route(){
      this.sportname = this.$route.params.sport;
      this.pageno = 0;
      if(typeof this.$route.query.view == "undefined")
      {
        this.viewonly = false;
      }
      if(this.$route.query.view == 'tips')
      {
        this.viewonly = true;
      }
      this.checkLeagueInfo();
    }
  },
  mounted(){
    this.sportname = this.$route.params.sport;
    this.checkLeagueInfo();
    
    if(this.$route.query.view == 'tips')
    {
      this.viewonly = true;
    }
    else
    {
      this.viewonly = false;
    }
  },
  methods:{
    gotomainpage(){
      this.viewonly = false;
      this.$router.push("/tipping/"+this.sportname);
    },
    changepage(action){
       if(action == 'next')
         this.pageno =  this.nextpage;
       else  
         this.pageno =  this.previouspage;
       
       this.totalmatches = 0;
       this.selection = [];
       this.checkLeagueInfo();  
    },
    sendemail(){
      this.disablebtn = true;
      this.$store.dispatch("sendEmail",{sport:this.sportname,week:this.currentweek}).then((response) =>
       {
         if(response.status)
         {
          this.disablebtn = false;  
         }
       });  
    },
    checkLeagueInfo(){
       this.schedule = [];
       this.$store.dispatch("checkLeagueInfo",{sport:this.sportname,page:this.pageno}).then((response) =>
       {
          if(response.data.purchased == 0)
          {
            this.$router.push("/dashboard");
          }
          else if(response.data.purchased == 1)
          {
            this.getTeamStreak();
            
         
            if(response.data.roundcompleted == 1)
              this.hidenavigation = false;
            else
              this.hidenavigation = true;
         
            this.totalmatches = response.data.schedule.length;
            this.selection = [];
            if(response.data.schedule.length > 0)
            {
              for(var i = 0; i < response.data.schedule.length; i++)
              {
                var values = response.data.schedule[i];
                if(values.homeselected)
                {
                  this.selection.push({matchid:values.game,teamid:values.hometeamid})
                }
                if(values.awayselected)
                {
                  this.selection.push({matchid:values.game,teamid:values.awayteamid})
                }
              }
              this.currentweek  = response.data.week;
              this.schedule     = response.data.schedule;
              this.nextpage     = parseInt(this.currentweek) + 1;
              this.previouspage = parseInt(this.currentweek) - 1;

              if(this.currentweek == 0 && this.sportname == 'AFL')
                this.roundname = 'Opening Round';
              else
                this.roundname = 'Round '+this.currentweek;

              
              if(this.sportname == 'AFL' && this.currentweek == 1)
               { 
                  this.specialcase = 1;
                  this.previouspage = 0;
               }
              else
               { 
                  this.specialcase = 0;
               }
               console.log(this.specialcase);
            }
          } 
       });
    },
    getTeamStreak(){
       this.$store.dispatch("TeamStreak",{sport:this.sportname}).then((response) => {
          this.teamladder = response.data.ladder;
          this.season = response.data.season;
       });
    },
    makeselect(matchid,teamid,whichteam){
       if(whichteam == "hometeam")
       {
        this.$refs["match"+matchid][0].children[1].children[0].classList.remove('makedisable');  
        this.$refs["match"+matchid][0].children[1].children[2].classList.add('makedisable');  
       }
       else
       {
        this.$refs["match"+matchid][0].children[1].children[0].classList.add('makedisable');  
        this.$refs["match"+matchid][0].children[1].children[2].classList.remove('makedisable');  
       }
       
        var index  = this.selection.findIndex(p => p.matchid == matchid);
        if(index >= 0)
          this.selection[index].teamid = teamid;
        else
          this.selection.push({matchid:matchid,teamid:teamid});
    },
    confirmTips(){
       this.savemodel = true;
       /* 
        if(this.totalmatches == this.selection.length)
          this.showwarning = false;
        else
          this.showwarning = true;  
       */
    },
    saveTips(){
      this.showprogress = true;
      this.disablebtn   = true; 
      this.$store.dispatch("saveMyTips",{sport:this.sportname,selection:this.selection,week:this.currentweek}).then((response) => {
          this.showprogress = false;
          this.disablebtn   = false; 
          if(response.status)
          {
            this.savemodel = false; 
            this.viewonly = true;
            this.$router.push("/tipping/"+this.sportname+"?view=tips")  
          } 
      });
    } 
  }
} 
</script>
<style scoped>
h1{font-size: 20px;}
.tipping-match-container{box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; border-radius: 10px;}
.tipping-header {display: flex;flex-direction: column;min-width: 340px;min-height: 80px;margin: 0 auto 24px auto;border-radius: 10px;position: relative;background-color: #fff;padding: 12px 12px 0 12px;}
.tipping-header-top-section{max-height: 48px;flex-direction: row;align-items: center;justify-content: space-between;padding-bottom: 16px;box-sizing: border-box;display: flex; margin-top: 5px; border-bottom: 1px solid #dcdddf;}
.tipping-header-bottom-section{display: flex;justify-content: space-between;width: 100%;padding-top: 16px; margin-bottom: 16px;}

.teamladder { width: 336px; display: flex;    flex-flow: column;height: fit-content; border-radius: 10px; background-color: #fff;}
.blankhref { width: 60px;  display: block;}
.navigation{display: flex;}
a.navigation,a.navigation  span{color: #2196F3;}
.noschedule{text-align: center; color: black; font-weight: bold;}
.teamladder-footer{display: flex;height: 1.5rem;justify-content: center;align-items: center;font-size: 12px;color: #3772df;}
>>> .v-toolbar__content{display: block;}
>>> .v-toolbar__content p{font-size: 13px;}
>>> .theme--light.v-card > .v-card__text{color: black;}
.tipselected{color: green; text-transform: uppercase; font-weight: bold;}
.correct {background: green;color: white; border-radius: 50%;}
.wrong {background: #cf3333;color: white;border-radius: 50%;}
.makedisable img{opacity: 0.3;}
.progressbar { width: 200px; display: block;}
span.progval { font-size: 12px;  font-weight: bold;}
@media screen and (max-width: 850px){
.hidden-lg {  display: none!important;}
.column--main{max-width: 100%;}
}
@media screen and (max-width: 680px){
  .single-match .part-team {padding: 15px 15px 15px;}
  .progressbar { width: 120px; display: block;}
}
.no-tip {
    margin-top: 5px;
    width: 80px;
    display: block;
    background: orange;
    font-weight: bold;
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
}
.my-tip{
  margin-top: 5px;
    width: 80px;
    display: block;
    background: green;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
}
</style>
<style>
.column-section{display: flex; margin-bottom: 20px;}
.column--main{ max-width: 750px;flex: 1 1 750px;}
.column--side{padding-left: 48px;display: flex;flex: 1 1 336px;justify-content: flex-start;align-items: flex-start;max-width: 336px;}
.single-match{box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 9%);margin-bottom: 40px;}
.single-match .part-head {text-align: center;padding: 10px 0;border-radius: 0;position: relative;z-index: 2;overflow: hidden;border: 1px solid #ddd;}
.single-match .part-head .match-venue {font-size: 14px;font-weight: 400;color: #383838;}
.single-match .part-head:after {position: absolute;content: '';left: 0;top: 0;width: 100%;background: #f7f7f7;height: 100%;z-index: -1;opacity: .98;}
.single-match .part-team {padding: 15px 60px 15px;text-align: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.single-match .part-team .match-details .match-time { margin-bottom: 12px;}
.single-match .part-team .match-details .match-time span {display: block;text-transform: uppercase;}
.single-match .part-team .match-details .match-time span.date {font-weight: 500;color: #2a2a2a;opacity: .6;font-size: 14px;}
.single-match .part-team .match-details .match-time span.time {font-size: 24px;font-weight: 500;color: #f90505;}
.single-match span.versase {font-size: 18px;font-weight: 500;display: inline-block;position: relative;background: #fff;width: 40px;height: 40px;line-height: 34px;border-radius: 50%;color: #f90505;border: 2px solid rgba(56, 56, 56, 0.3);margin-bottom: 20px;font-style: italic;}
.single-match .part-team .match-details { display: inline-block;text-align: center;}
.single-match .part-team .single-team .logo {width: 80px; margin-bottom: 10px; display: inline-block;}
.single-match .part-team .single-team .logo img {width: 100%; height: 80px;}
.single-match .part-team .single-team span.team-name {text-transform: uppercase;font-weight: 600;font-size: 14px;color: #383838;display: block;opacity: .9; }
</style>