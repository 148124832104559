import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
      theme: {
        themes: {
          light: {
            primary: '#383838',
            secondary: '#1976d2',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
          dark:{
            primary: '#000000',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          }
        },
      },   
});
