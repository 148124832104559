<template> 
 <div>
    <div class="scorehead">My Score Card for  {{ sportname }}</div>
    <div class="scoreboard">
        <div class="board">
           <div class="board-heading">
             <div class="season">Season</div>
             <div class="week-col" v-for="(val,key) in column[sportname]" :key="key">
               <span v-if="sportname== 'AFL'">
                  <span v-if="val == 1">OR</span>
                  <span v-else>{{ val-1  }}</span>
                </span>
               <span v-else>{{ val }}</span> 
             </div>
             <div class="total text-center">Total</div>
             <div class="total text-center">Rank</div>
           </div> 
           <div class="board-data">
             <div v-for="(val,key) in scoresheet" :key="key">
                <div class="season">{{val.season}}</div>
                <div class="week-col" v-for="(val,key) in val.score" :key="key">
                  <div class="diagonal">
                    <div class="topscore">{{val.weekscore}}</div>
                    <div class="bottomvalue">{{ val.total }}</div>
                  </div>
                </div>
                <div class="total text-center">{{ val.total }}</div>
                <div class="rank text-center">{{ val.rank }}</div>
             </div>
           </div>
        </div>
    </div>
 </div>
</template>
<script>
 export default{
    name:'Myscorecard',
    data(){
        return{
            sportname:'',
            column:{"AFL": 25,"NRL" : 27,"Football":38,"NFL":18}, 
            scoresheet:[]
        }
    },
    created(){
        this.sportname = this.$route.params.sport;
        this.getMyScorecard();
    },
    methods:{
        getMyScorecard(){
            this.$store.dispatch("getmyScoreCard",{sport:this.sportname}).then((response) => {
               if(response.data.length > 0)
               {
                 this.scoresheet = response.data;
               } 
            });
        }
    } 
 }
</script>
<style scoped>
 .scorehead{background: #000; color: white; text-align: center; padding: 5px;margin-top: 5px; margin-bottom: 5px; font-size: 22px; font-weight: bold;}
 .scoreboard{background: #a90f0f; min-height: 450px; margin-bottom: 5px; color: white; font-weight: 500;}
 .board { padding: 10px;}
 .board-heading,.board-data > div{display: flex;}
 .season{width: 6%;}
 .total{width: 6%;}
 .rank{width: 6%;}
 .week-col {  width: 3.6%;  text-align: center;}
 .board-data > div > div{background: white; border-top: 1px solid #383838; border-right: 1px solid #383838; color: black;}
 .board-data > div > div:first-child{border-left: 1px solid #383838;}
 .diagonal{background:#fff !important;background: linear-gradient(to right bottom, #fff 50%, #BDDDF7 50%) !important;min-height: 30px;position: relative;font-size: 12px;font-weight: normal;}    
 .topscore {width: 50%;position: absolute;top: 0;}
 .bottomvalue{width: 50%;right: 5px;bottom: 0;position: absolute;}    
 @media(max-width:767px){
  .scorehead{width: 1000px;}
  .scoreboard{width: 1000px;}
  
 }
</style>